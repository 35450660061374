import Choices from 'choices.js';
import Flow from '@flowjs/flow.js';
import dragula from 'dragula';
import * as keyVisuals from './campaigns-key-visuals.js';

import { handleValidation } from '../forms.js';
import { t } from 'i18next';

// Element.closest() polyfill
if (!Element.prototype.closest) {
	Element.prototype.closest = function (s) {
		var el = this;

		do {
			if (Element.prototype.matches.call(el, s)) { return el; }
			el = el.parentElement || el.parentNode;
		} while (el !== null && el.nodeType === 1);
		return null;
	};
}


// Funkcionalita na strance s tiskovymi daty (+ sdilena funkce pridavani vice souboru/odkazu pod sebe i pro klicove vizualy)
document.addEventListener('DOMContentLoaded', function () {
	if (!document.getElementById('campaigns-data') && !document.getElementById('campaigns-key-visuals')) { return; }

	var dataSection = document.getElementById('campaigns-data') || '';
	var dataForm = '';

	// Zobrazeni dat
	if (dataSection) {
		dataForm = dataSection.querySelector('form');
		interactiveDataContainer(dataSection);
	}

	// Ziskat URL pro posilani/editaci/odstraneni polozek
	var apiUrl = '';

	var fieldset;

	// Pridavani a odebirani dat
	if (!dataSection && document.querySelector('#data-templates')) {
		// Pokud jsem na klicovych vizualech + je tam minimalne jeden -> hledam url
		apiUrl = document.querySelector('#data-templates').getAttribute('data-visual-source');
	}
	else if (dataForm) {
		apiUrl = dataForm.action;
		// Zakazat submit formulare
		dataForm.addEventListener('submit', e => e.preventDefault());
	}

	// Funkcionalita pridavani linku a editace linku
	function setToInput (item) {
		let productContainer = item.parentElement.parentElement;
		let links = productContainer.querySelectorAll('fieldset div.link');
		let lastLink = links[links.length - 1];
		let isEdit = item.classList.contains('edit');

		let addIcon = isEdit ? item.parentElement.parentElement.querySelector('.add') : lastLink.querySelector('.add');
		let editIcon = isEdit ? item.parentElement.parentElement.querySelector('.edit') : lastLink.querySelector('.edit');
		let linkInput = isEdit ? item.parentElement.parentElement.querySelector('.input-container') : lastLink.querySelector('.input-container');
		let linkText = isEdit ? item.parentElement.parentElement.querySelector('a.link-text') : lastLink.querySelector('a.link-text');

		linkInput.classList.remove('hidden');
		linkText.classList.add('hidden');

		addIcon.classList.remove('hidden');
		editIcon.classList.add('hidden');

		linkInput.querySelector('input').focus();
		handleValidation();
	}

	function setToLink (item) {
		let productContainer = item.parentElement.parentElement;

		productContainer.querySelector('.link-text').classList.remove('hidden');
		productContainer.querySelector('.input-container').classList.add('hidden');

		productContainer.querySelector('.edit').classList.remove('hidden');
		productContainer.querySelector('.add').classList.add('hidden');

		if (dataSection) {
			productContainer.querySelector('.check').classList.remove('hidden');
			productContainer.querySelector('.reject').classList.remove('hidden');
		}
	}

	// Funkce pro pridani polozky
	function addItem (item, interact, fakeId) {
		interact = (typeof interact === 'boolean' ? interact : false);

		// Najit spravny fieldset
		if (dataSection) {
			if (document.querySelector(`[data-product-type="${ item.productType }"]`)) {
				fieldset = document.querySelector(`[data-product-type="${ item.productType }"][data-visual="${ item.visual }"] fieldset`);
			}
			else { fieldset = document.querySelector(`[data-visual="${ item.visual }"] fieldset`); }
		}
		else { fieldset = document.querySelector(`[data-visual="${ item.visual }"] fieldset`); }

		// Zkopirovat sablonu
		let html = document.querySelector(`[data-template-${ item.type }]`).outerHTML;

		// Nahradit promenne
		for (let prop in item) {
			if (item.hasOwnProperty(prop)) {
				html = html.replace(new RegExp('{{' + prop + '}}', 'g'), item[prop]);
			}
		}

		// Vytvorit element s nahrazenym HTML
		let element = document.createElement(null);
		element.innerHTML = html;
		element = element.firstChild;

		// Odstranit data-template atribut
		element.removeAttribute(`data-template-${ item.type }`);

		// Vlozit do fieldsetu (pokud je nahran nejaky soubor)
		if (item.type === 'link' || (item.type === 'file' && !interact)) { fieldset.appendChild(element); }
		else if (item.type === 'file' && interact) {
			fieldset.appendChild(element);

			element.querySelector('a.file').classList.add('disabled');
			element.querySelectorAll('.icon-container a').forEach(icon => { icon.classList.add('disabled'); });

			// Add progress bar to uploading file
			let progress = document.createElement('progress');
			progress.value = 0;
			progress.max = 1;
			fieldset.appendChild(progress);
		}

		// Tlacitko pro odstraneni
		element.querySelector('a.remove').addEventListener('click', e => {
			e.preventDefault();
			removeItem(element);
		});

		// Schvalovaci a zamitavaci funkcionalita - jen na Datech pro grafika
		if (dataSection) {
			// Tlacitko pro schvaleni
			element.querySelector('a.check').addEventListener('click', e => {
				e.preventDefault();
				setStatusItem(item, '', e.target);
			});

			// Tlacitko pro reject
			element.querySelector('a.reject').addEventListener('click', e => {
				e.preventDefault();

				var isDenied = document.querySelector(`div[data-id="${ item.id }"] a.reject.denied`);

				if (!isDenied) {
					// Create popup
					window.popup({
						type: 'comment',
						text: `<form><textarea id="textbox" placeholder="${ t('Vložte komentář pro zamítnutí.') }" data-nette-rules=\'[{"op":":filled","required":"","msg":"${ t('Zadejte prosím komentář pro zamítnutí.') }"}]\'></textarea></form>`,
						confirmBtn: {
							text: t('Uložit'),
							callback: function () {
								let comment = document.querySelector('#popup textarea').value || null;
								item.comment = comment;
								setStatusItem(item, comment, e.target);
							},
						},
					});
					var btnConf = document.querySelector('#popup a.confirm');

					// Nechat submit disabled, dokud neni komentar vyplnen
					btnConf.classList.add('disabled');
					handleValidation();

					var commentArea = document.getElementById('textbox');
					commentArea.addEventListener('keydown', function (x) {
						// Umoznit v textarea psat tab misto tabindexovani
						if (x.key == 'Tab') {
							x.preventDefault();
							var start = this.selectionStart;
							var end = this.selectionEnd;

							// set textarea value to: text before caret + tab + text after caret
							this.value = this.value.substring(0, start) + '\xa0\xa0\xa0\xa0' + this.value.substring(end);

							// put caret at right position again
							this.selectionStart = this.selectionEnd = start + 4;
						}

						// Hlidat, jestli je komentar vyplnen
						if (commentArea.value) { btnConf.classList.remove('disabled'); }
						else { btnConf.classList.add('disabled'); }
					});
				}

				else {
					// Zobrazit komentar
					var commentText = item.comment;
					// Umoznit enter/vice enteru z inputu do <p></p>
					if (commentText.match(/(?:\r\n|\r|\n)/g)) { commentText = commentText.replace(/(?:\r\n|\r|\n)/g, '<br>'); }
					// Umoznit tab/vice tabu z inputu do <p></p>
					if (commentText.match(/\t/g)) { commentText = commentText.replace(/\t/g, '&nbsp;&nbsp;&nbsp;&nbsp;'); }
					// Umoznit mezeru/vice mezer z inputu do <p></p>
					if (commentText.match(/ /g)) { commentText = commentText.replace(/ /g, '\xa0'); }

					// Create popup
					window.popup({
						type: 'comment',
						text: `<form><p>${ commentText }</p></form>`,
					});
				}
			});
		}

		// Inicializovat funkcionalitu odkazu
		if (item.type === 'link') {
			var input = element.querySelector('input');
			var checkIcon = document.querySelector(`div[data-id="${ item.id }"] .check`);
			var rejectIcon = document.querySelector(`div[data-id="${ item.id }"] .reject`);

			// Inicializace buttonu ulozeni odkazu
			document.querySelector(`div[data-id="${ item.id }"] a.add`).addEventListener('click', e => {
				e.preventDefault();

				item.element.classList.remove('editing');
				// Odstranit zakazani pridavani souboru/odkazu, pokud probiha editace odkazu
				document.querySelectorAll('.add-item span[data-add]').forEach(el => { el.classList.remove('disabled'); });

				if (item.element.getAttribute('data-id') === 'null') { item.element.remove(); }

				uploadItem(item, input.value);
				setToLink(e.target);
			});

			// Inicializace buttonu editace odkazu
			document.querySelector(`div[data-id="${ item.id }"] a.edit`).addEventListener('click', e => {
				e.preventDefault();

				setToInput(e.target);

				// Smazat prave pridany neulozeny link
				if (document.querySelector('.link[data-id="null"]')) { document.querySelector('.link[data-id="null"]').remove(); }

				// Zrusit editaci predchoziho linku, pokud jdu editovat jiny
				if (document.querySelector('.editing')) {
					if (dataSection) {
						document.querySelector('.editing .check').classList.remove('hidden');
						document.querySelector('.editing .reject').classList.remove('hidden');
					}

					document.querySelector('.editing .input-container').classList.add('hidden');
					document.querySelector('.editing .link-text').classList.remove('hidden');
					document.querySelector('.editing .edit').classList.remove('hidden');
					document.querySelector('.editing .add').classList.add('hidden');

					document.querySelector('.editing').classList.remove('editing');
				}

				if (dataSection) { checkIcon.classList.add('hidden'); rejectIcon.classList.add('hidden'); }

				item.element.classList.add('editing');

				// Zakazat pridavani souboru/odkazu, pokud probiha editace odkazu
				document.querySelectorAll('.add-item span[data-add]').forEach(el => { el.classList.add('disabled'); });
			});

			// Pokud je url spatne zadana, neumoznit odkaz ulozit
			input.addEventListener('keyup', e => {
				setTimeout(() => {
					if (e.target.parentElement.classList.contains('error') || e.target.value === '') {
						e.target.parentElement.parentElement.querySelector('.add').classList.add('disabled');
					}
					else if (e.target.value !== '') {
						e.target.parentElement.parentElement.querySelector('.add').classList.remove('disabled');
					}
				}, 50);
			});

			// Pokud chci url upravit, odstranit disabled ulozit na focus na input
			input.addEventListener('focus', e => {
				setTimeout(() => {
					if (e.target.value !== '') {
						e.target.parentElement.parentElement.querySelector('.add').classList.remove('disabled');
					}
				}, 50);
			});
		}

		// Pridat referenci na element do item
		item.element = element;

		if (fakeId) { item.element.setAttribute('file-id', fakeId); }

		return item;
	}

	// Funkce pro odebrani polozky
	function removeItem (item) {
		var dataId = parseInt(item.getAttribute('data-id')) || null;

		// Disable until request is completed
		document.querySelectorAll('div[data-id]:not([data-template-link]) .icon-container a').forEach(elem => {
			if (!elem.parentNode.parentNode.querySelector('input[type="url"]')) { elem.classList.add('disabled'); }
		});

		// Odstraneni polozky
		function removeElement () {
			item.remove();

			// Odstranit zakazani pridavani souboru/odkazu, pokud dojde k odstraneni linku v prubehu editace (pokud neprobiha editace jineho)
			if (item.classList.contains('link') && !document.querySelector('.editing')) {
				document.querySelectorAll('.add-item span[data-add]').forEach(el => { el.classList.remove('disabled'); });
			}
		}

		// Pokud je id null, polozka jeste ani nebyla ulozena na serveru, proto muze byt odstranena rovnou
		if (dataId === null) {
			removeDisabled();
			removeElement();
			return;
		}

		// Request na odstraneni polozky
		let data = {
			id: dataId,
		};

		naja.makeRequest('DELETE', apiUrl, data, {
			history: false,
		}).then(() => {
			removeDisabled();
			removeElement();
		});
	}

	function removeDisabled () {
		document.querySelectorAll('div[data-id]:not([data-template-link]) .icon-container a.disabled').forEach(elem => {

			if (elem.parentNode.parentNode.nextElementSibling) {
				// Nemazat disabled u uploadujicich se souboru
				if (elem.parentNode.parentNode.nextElementSibling.tagName.toLowerCase() !== 'progress') {
					elem.classList.remove('disabled');
				}
			}
			else if (!elem.parentNode.parentNode.querySelector('input[type="url"]')) { elem.classList.remove('disabled'); }
			else if (elem.parentNode.parentNode.querySelector('input').value !== '') { elem.classList.remove('disabled'); }
		});
	}

	// Funkce pro schvaleni/neschvaleni polozky - odesle status
	function setStatusItem (item, comment, target) {
		var url = document.querySelector('[data-send-status]').getAttribute('data-send-status');
		var status;

		// Disable until request is completed
		document.querySelectorAll('div[data-id]:not([data-template-link]) .icon-container a').forEach(elem => {
			if (!elem.parentNode.parentNode.querySelector('input[type="url"]')) { elem.classList.add('disabled'); }
		});

		if (comment || comment === null) {
			status = item.element.querySelector('.reject[data-status]').getAttribute('data-status');
			naja.makeRequest('POST', url, { id: item.id, status: status, comment: comment }, { history: false }).then(() => {
				removeDisabled();
				setStatusVisual(target);
			});
		}
		else {
			status = item.element.querySelector('.check[data-status]').getAttribute('data-status');
			naja.makeRequest('POST', url, { id: item.id, status: status }, { history: false }).then(() => {
				removeDisabled();
				setStatusVisual(target);
			});
		}
	}

	// Nastylovat dle statusu (schvaleno/neschvaleno)
	function setStatusVisual (stat) {
		var status = stat.status || stat.getAttribute('data-status');
		var element = stat.element || stat.parentElement;

		if (status === 'approved') {
			element.querySelector('.check').classList.add('success');
			element.querySelector('.reject').classList.add('hidden');
		}
		else if (status === 'denied') {
			element.querySelector('.check').classList.add('hidden');
			element.querySelector('.reject').classList.add('denied');
		}
		if ((status === 'denied' || status === 'approved') && element.querySelector('.edit')) { element.querySelector('.edit').classList.add('hidden'); }
	}

	// Funkce pro upload polozky
	function uploadItem (item, value) {
		// Upload noveho odkazu
		if (item.type === 'link' && item.id === null) {
			let data = new FormData();

			if (dataSection) { item.productType = item.productType ? item.productType : null; data.append('productType', item.productType); }
			data.append('visual', item.visual);
			data.append('type', item.type);
			data.append('value', value);

			naja.makeRequest('POST', apiUrl, data, {
				history: false,
			}).then(response => {
				item.id = dataSection ? response.printData.id : response.source.id;
				item.value = value;

				// Set data-id after link upload, jinak null
				item.element.setAttribute('data-id', item.id);

				if (dataSection) { naja.snippetHandler.updateSnippets(response.snippets); }
				addItem(item);

			}).catch(() => {
				item.element.querySelector('input').value = item.value;
			});
		}

		// Zmena existujiciho odkazu
		else if (item.type === 'link') {
			let data = {
				id: item.id,
				productType: item.productType || null,
				visual: item.visual,
				type: item.type,
				value: value,
			};

			naja.makeRequest('PUT', apiUrl, data, {
				history: false,
			}).then(response => {
				item.value = dataSection ? response.printData.value : response.source.value;

				item.element.querySelector('.link-text').textContent = item.value;
				item.element.querySelector('.link-text').href = item.value;
				item.element.querySelector('.input').value = item.value;
			}).catch(() => {
				item.element.querySelector('input').value = item.value;
			});
		}
	}

	// Pridat listenery na tlacitka odkaz - pridani polozky
	document.querySelectorAll('[data-visual]').forEach(cell => {
		// Visual is rerendered twice - so prevent add click listener twice
		if (!cell.hasAttribute('click-added')) {

			let productType = parseInt(cell.getAttribute('data-product-type')) || null;
			let visual = parseInt(cell.getAttribute('data-visual'));

			cell.setAttribute('click-added', '');

			// Listener na tlacitko odkaz
			cell.querySelector('[data-add="link"]').addEventListener('click', e => {
				e.preventDefault();

				addItem({
					id: null,
					productType,
					visual,
					type: 'link',
					value: '',
				}, true);

				var blankLink = cell.querySelector('fieldset div.link[data-id="null"]');

				if (blankLink.querySelector('.link-text')) { blankLink.querySelector('.link-text').classList.add('hidden'); }
				if (dataSection) {
					if (blankLink.querySelector('.check')) { blankLink.querySelector('.check').classList.add('hidden'); }
					if (blankLink.querySelector('.reject')) { blankLink.querySelector('.reject').classList.add('hidden'); }
				}

				// Zakazat pridavani souboru/odkazu, pokud probiha editace odkazu
				document.querySelectorAll('.add-item span[data-add]').forEach(el => { el.classList.add('disabled'); });

				setToInput(e.target);
			});
		}
	});

	// Nacist vsechny ulozene polozky
	naja.makeRequest('GET', apiUrl, null, {
		history: false,
	}).then(response => {
		if (Array.isArray(response) && response.length != 0) {
			response.forEach(resp => {
				addItem(resp);
				if (dataSection) { setStatusVisual(resp); }
			});
		}
	}).catch(err => new TempMessage({
		html: err,
		type: 'warning',
	}));

	// // Nastavit upozorneni na opusteni stranky, pokud se stale nahravaji soubory
	// window.addEventListener('beforeunload', e => {
	// 	let allFilesUploaded = filesToUpload.every(fileInput => fileInput.file === null || fileInput.file.uploaded);

	// 	if (!allFilesUploaded) {

	// 		// Alespon nejake zobrazeni zpravy - funguje pouze ve Firefoxu
	// 		new TempMessage({
	// 			html: 'Některé soubory se stále nahrávají. Při opuštění stránky <b>dojde ke ztrátě těchto souborů</b>.',
	// 			delay: 100, // Confirm blokuje provadeni Javascriptu, takze zprava zustava zobrazena, dokud se uzivatel nerozhodne, potom hned zmizi
	// 			type: 'warning',
	// 		});

	// 		e.preventDefault();
	// 		e.returnValue = '';
	// 	}
	// });

	// Inicializace upload filu -----------------------------
	(function () {
		var item = {
			id: null,
			productType: null,
			visual: document.querySelector('[data-visual]') ? parseInt(document.querySelector('[data-visual]').getAttribute('data-visual')) : null,
			type: 'file',
			url: '#',
			value: '',
		};

		var fileUpload = new Flow({
			target: apiUrl,
			query: function (FlowFile) { return { productType: FlowFile.productType, visual: item.visual, type: 'file', filename: FlowFile.fileName }; },
			headers: { 'X-Requested-With': 'XMLHttpRequest' },
			testChunks: false,
			allowDuplicateUploads: true,
		});

		// Add click event to Pridat soubor
		fileUpload.assignBrowse(document.querySelectorAll('span[data-add="file"]'));

		// Before file(s) are uploaded
		fileUpload.on('fileAdded', function (file, event) {
			let productTypeItem = event.target.closest('[data-product-type]').getAttribute('data-product-type');
			let filename = document.querySelector(`[data-product-type="${ productTypeItem }"]`).getAttribute('data-filename');
			let fullFilename = filename ? `${ filename }.${ file.getType() }` : null;

			file.fakeId = file.chunkSize * Math.random();
			file.productType = productTypeItem !== 'null' ? parseInt(productTypeItem) : null;
			file.fileName = fullFilename || file.name;

			item.productType = file.productType;
			item.value = file.fileName;

			addItem(item, true, file.fakeId);

			var element = document.querySelector(`div[file-id="${ file.fakeId }"]`);
			file.element = element;

			let timeRemainingEl = document.createElement('p');
			file.element.querySelector('.icon-container').before(timeRemainingEl);
			file.progressRound = 0;
		});

		// It handles the submit/upload of the file
		fileUpload.on('filesSubmitted', function () { fileUpload.upload(); });

		// Track the file uploading progress
		fileUpload.on('fileProgress', function (file) {
			if (file.element) {
				if (file.element.nextElementSibling) {

					file.element.nextElementSibling.setAttribute('progress-id', file.fakeId);
					file.element.nextElementSibling.value = file.progress();

					// Add uploaded size MB / actual size MB, time remaining s/min
					if (file.progressRound < file.progress().toFixed(2) || file.progress().toFixed(3) == 0.010) {
						var uploadedSize = (file.sizeUploaded() / (1024 * 1024)).toFixed(1);
						var fileSize = (file.size / (1024 * 1024)).toFixed(1);
						const secondsLeft = file.timeRemaining();
						const timeLeft = secondsLeft < 60 ? `${ secondsLeft } s` : `${ Math.floor(secondsLeft / 60) } min`;

						file.element.querySelector('p').textContent = `${ uploadedSize } MB / ${ fileSize } MB, ${ timeLeft }`;
					}
					file.progressRound = file.progress().toFixed(2);
				}
			}
		});

		fileUpload.on('fileSuccess', function (file, message) {
			var response = JSON.parse(message);

			file.id = response.printData ? response.printData.id : response.source.id;
			item.id = file.id;

			item.url = response.printData ? response.printData.url : response.source.url;

			// Set data-id after file upload, jinak null
			file.element.setAttribute('data-id', file.id);

			if (dataSection) { naja.snippetHandler.updateSnippets(response.snippets['snippet--rightMenu']); }

			// Remove all signs of not uploaded file (progress bar + disabled)
			document.querySelector(`progress[progress-id="${ file.fakeId }"]`).remove();
			document.querySelector(`div[data-id="${ file.id }"] a.file`).classList.remove('disabled');
			document.querySelectorAll(`div[data-id="${ file.id }"] .icon-container a`).forEach(icon => { icon.classList.remove('disabled'); });
			file.element.querySelector('p').remove();

			new TempMessage({
				html: response.messages[0].message,
				type: response.messages[0].type,
			});
		});

		fileUpload.on('fileError', function (file, message) {
			var response = JSON.parse(message);

			// Remove all signs of not uploaded file (progress bar + disabled)
			document.querySelector(`progress[progress-id="${ file.fakeId }"]`).remove();
			file.element.querySelector('p').remove();

			new TempMessage({
				html: response.messages[0].message,
				type: response.messages[0].type,
			});
		});

		fileUpload.on('complete', function () {
			item = {
				id: null,
				productType: null,
				visual: parseInt(document.querySelector('[data-visual]').getAttribute('data-visual')),
				type: 'file',
				url: '#',
				value: '',
			};
		});
	})();

	naja.afterUpdateFunctions.push(function () {
		if (dataSection) { interactiveDataContainer(dataSection); }
	});
});


// Funkcionalita na strance s downloadem tiskovych dat
document.addEventListener('DOMContentLoaded', function () {
	if (!document.getElementById('campaigns-data-download')) { return; }

	// Zobrazeni tabulky
	interactiveDataContainer(document.getElementById('campaigns-data-download'));
});


// Funkcionalita na strance s vyberem pobocek
document.addEventListener('DOMContentLoaded', function () {
	if (!document.getElementById('campaigns-branches')) { return; }

	function switchContent () {
		document.querySelectorAll('input[type="radio"]').forEach(function (input, i) {
			if (input.checked) {
				if (i == 0) { document.getElementById('branch-selection').classList.remove('show'); }
				else { document.getElementById('branch-selection').classList.add('show'); }
			}
		});
	}

	switchContent();
	document.getElementById('content-switch').addEventListener('click', switchContent);

	naja.afterUpdateFunctions.push(function () {
		switchContent();
		document.getElementById('content-switch').addEventListener('click', switchContent);
	});
});


// Funkcionalita na strance s vyberem tiskaren
document.addEventListener('DOMContentLoaded', function () {
	if (!document.getElementById('campaigns-print')) { return; }

	var radios = Array.prototype.slice.call(document.querySelectorAll('input[type="radio"]'), 0);
	var url = document.querySelector('[data-change-partition]').getAttribute('data-change-partition');
	var selects;

	radios.forEach(input => {

		input.addEventListener('click', e => {
			let partition = e.target.value;
			naja.makeRequest('POST', url, { partition: partition }, { history: false });

			radios.map(btn => { if (btn !== e.target) { btn.checked = false; } });
		});
	});

	if (document.getElementById('multiple-printers')) { document.getElementById('multiple-printers').classList.add('show'); }

	// Odebira tridu pro zabarveni printed itemu - pokud zmenim tiskarnu
	function setPrinted () {
		selects = document.querySelector('#one-printer') ? document.querySelectorAll('#one-printer select') : document.querySelectorAll('#multiple-printers select');

		selects.forEach(el => {
			el.addEventListener('change', e => {

				if (e.target.options[e.target.selectedIndex].text != 'N/A') {
					if (e.target.value !== '0') { e.target.classList.remove('printed'); }
					else { e.target.classList.add('printed'); }
				}
			});
		});
	}

	setPrinted();
	naja.afterUpdateFunctions.push(function () {
		if (document.getElementById('multiple-printers')) { document.getElementById('multiple-printers').classList.add('show'); }
		setPrinted();
	});
});

// Funkcionalita na strance s klicovymi vizualy --------------------------------
export var theChoices;
export var selectedPrevious = [];

document.addEventListener('DOMContentLoaded', function () {
	if (!document.getElementById('campaigns-key-visuals')) { return; }

	var part;	// O kterou cast segmentace se jedna (mesta, zony, tagy, ...)

	function segmentationInitial (multiselect) {
		var visualBlock = multiselect.getAttribute('data-visual-block');

		// Add selected items to each block on (re)load
		if (!document.querySelector(`.selected-${ visualBlock }`)) { keyVisuals.createSelected(visualBlock); keyVisuals.showSelected(visualBlock); }

		// Open popup on <a> link click + handle popup
		if (multiselect.querySelector('a')) {
			multiselect.querySelector('a').addEventListener('click', e => {
				e.preventDefault();

				part = multiselect.getAttribute('data-visual-block');
				handleSegmentation();
			});
		}
	}

	// Inicializace funkci popupu
	(function popupInitial () {
		// Get back to previous state - when popup is open + click outside the popup
		document.querySelector('#popup:not(.header):not(.content)').addEventListener('click', () => { keyVisuals.getBackSelected(part); });

		document.querySelector('.add-all').addEventListener('click', () => {
			(function onSelectAll () {
				keyVisuals.handleSelectAll(false, part);

				keyVisuals.handleDisabled();
				keyVisuals.setListHeight();
				keyVisuals.setInputWidth();
			})();
		});

		document.querySelector('.hide-disabled').addEventListener('click', () => { keyVisuals.handleDisabled(); });
	})();

	function handleSegmentation () {
		if (part !== 'posm-type') { selectedPrevious = document.querySelectorAll(`#visuals .choices-multiple.${ part } option`); }
		else { selectedPrevious = document.querySelectorAll(`#visuals .choices-multiple.${ part } optgroup`); }

		// Clone select to popup (+ choose if Choices select get its opts from html select or chosenAll)
		let selectContainer = document.createElement('div');
		let theSelect = document.getElementsByClassName(part)[0];
		let selectClone = theSelect.cloneNode(true);
		selectContainer.appendChild(selectClone);

		// Add zone to selected items in popup selectbox
		if (part === 'posm-type') {
			selectContainer.querySelectorAll('option').forEach(opt => { opt.textContent = opt.textContent + ' (' + opt.parentElement.label + ')'; });
		}

		// Create popup
		window.popup({
			type: 'grid',
			text: selectContainer.innerHTML,
			confirmBtn: {
				text: t('Uložit'),
				callback: function () { keyVisuals.sendSelected(part); },
			},
			cancelBtn: {
				text: t('Zrušit'),
				callback: function () { keyVisuals.getBackSelected(part); },
			},
		});

		// Multiselect handler - vytvori z obycejneho selectu (u kazdeho bloku v segmentaci) spec. multiselect (v popupu)
		theChoices = new Choices(document.querySelector('#popup .choices-multiple'), {
			removeItemButton: true,
			itemSelectText: '',
			noChoicesText: t('Žádné možnosti k dispozici'),
			noResultsText: t('Nebyly nalezeny žádné výsledky'),
			loadingText: t('Načítám'),
		});

		document.querySelector('#popup .choices-multiple').addEventListener('highlightItem', () => {
			keyVisuals.removeZones(part);
		});

		document.querySelector('.choices__input.choices__input--cloned').addEventListener('keyup', () => {
			if (document.querySelector('.choices__group')) { keyVisuals.removeZones(part); }
		});

		keyVisuals.setListHeight();

		keyVisuals.setInputWidth();

		keyVisuals.removeZones(part);

		// Pokud je vybrano "Vybrat vse" a odeberu nejakou moznost -> "Vybrat vse" = uncheck
		// Kdyz mam vybrano vse + Skryt disabled + odeberu moznost z vybranych -> disabled zustanou skryte
		document.querySelector('#popup select').addEventListener('change', () => {
			(function onSelectAll () {
				keyVisuals.handleSelectAll(true, part);

				keyVisuals.handleDisabled();
				keyVisuals.setListHeight();
				keyVisuals.setInputWidth();
			})();

			keyVisuals.setListHeight();

			// Kdyz pri "skryt disabled" vybiram dalsi opt, je handleDis. rychlejsi, takze bez timeotu nezustane schovane
			setTimeout(() => {
				keyVisuals.handleDisabled();
				keyVisuals.removeZones(part);
				keyVisuals.setInputWidth();
			}, 0);
		});
	}

	document.querySelectorAll('table:not(.disable-segmentation) [data-visual-block]').forEach(segmentationInitial);

	naja.afterUpdateFunctions.push(function () {
		document.querySelectorAll('table:not(.disable-segmentation) [data-visual-block]').forEach(segmentationInitial);
	});
});

// Klicove vizualy - prave menu - seznam vizualu funkcionalita
document.addEventListener('DOMContentLoaded', function () {
	if (!document.querySelector('[data-visuals-ids]')) { return; }

	var sideMenu = document.getElementById('visuals-list');
	var scrollEl = document.querySelector('.mobile-popup[data-mobile-popup="options"] .content .content');
	var url = document.querySelector('.campaigns-visual [data-visuals-ids]').getAttribute('data-visuals-ids');

	// Razeni vizualu
	function moveItems () {
		// Zapnout drag and drop jne pokud nejde o import vizualu
		if (sideMenu && sideMenu.getAttribute('data-enable-drag-and-drop') === 'true') {

			dragula({
				containers: [ document.getElementById('visuals-list') ],
				direction: 'vertical',

			}).on('drag', function () {
				// This prevents the default page scrolling while dragging an item.
				scrollEl.classList.add('no-scroll');

			}).on('drop', function () {
				scrollEl.classList.remove('no-scroll');

				// Send array of reordered visuals
				let visualsList = [];
				document.querySelectorAll('#visuals-list > a').forEach(visual => {
					visualsList.push(visual.getAttribute('name'));
				});
				naja.makeRequest('POST', url, { visuals: visualsList }, { history: false });

				document.querySelector('section.smaller').removeAttribute('dragged');
			});
		}
	}

	moveItems();
	naja.afterUpdateFunctions.push(function () {
		if (!document.querySelector('section.smaller').hasAttribute('dragged')) { moveItems(); }
		// Prevent render right menu twice
		document.querySelector('section.smaller').setAttribute('dragged', '');
	});
});

// Zobrazeni tabulky s tiskovymi daty
function interactiveDataContainer (container) { // eslint-disable-line
	container = container || document;
	var dataTable = container.querySelector('.data-container');

	// Zkopirovani title do vsech sloupcu
	// Tam kde je fieldset (editovatelne) jako <label>
	// Tam kde neni fieldset (needitovatelne) jako <strong>
	if (dataTable) {
		dataTable.querySelectorAll('.data-column .data-cell').forEach(function (col, i) {
			if (!col.querySelector('strong')) { return; }
			var title = col.querySelector('strong').innerHTML;

			dataTable.querySelectorAll('.data-column .data-cell:nth-of-type(' + (i + 1) + ')').forEach(function (cell) {
				var fieldset = cell.querySelector('fieldset');
				var infoIcon;

				if (fieldset) {
					fieldset.insertAdjacentHTML('afterbegin', '<label>' + title + '</label>');
					infoIcon = fieldset.querySelector('label div[data-info]');
				}
				else {
					cell.insertAdjacentHTML('afterbegin', '<strong>' + title + '</strong>');
					infoIcon = cell.querySelector('strong div[data-info]');
				}

				if (infoIcon) { window.initInfoIcon(infoIcon); }
			});
		});
	}
}
