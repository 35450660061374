import { initFlatpickr } from './config/flatpickr';
import { t } from 'i18next';

// NodeList.forEach polyfill
if (window.NodeList && !NodeList.prototype.forEach) {
	NodeList.prototype.forEach = function (callback, thisArg) {
		thisArg = thisArg || window;
		for (var i = 0; i < this.length; i++) {
			callback.call(thisArg, this[i], i, this);
		}
	};
}


// Initialize ajax
document.addEventListener('DOMContentLoaded', function () {
	if (!naja.initialized) {
		naja.initialize({
			timeout: 0,
		});
	}
});


document.addEventListener('DOMContentLoaded', function () {
	// MAGIC: Touchstart listener zpusobi, ze v iOS se zacnou pouzivat CSS :active styly apod.
	window.addEventListener('touchstart', function () {
		return;
	});


	// Inicializace fotek
	document.querySelectorAll('div.photo').forEach(function (div) {
		new Photo(div);
	});

	naja.afterUpdateFunctions.push(function () {
		this.querySelectorAll('div.photo').forEach(function (div) {
			new Photo(div);
		});
	});


	// Inicializace souboru
	document.querySelectorAll('div.file').forEach(function (div) {
		new FileInput(div);
	});

	naja.afterUpdateFunctions.push(function () {
		this.querySelectorAll('div.file').forEach(function (div) {
			new FileInput(div);
		});
	});

	// Custom autosubmit
	// Neni primo na inputu apod. kvuli prekreslovani celeho formulare
	(function () {
		var customSubmit = function (buttonName) {
			document.querySelector(`button[name="${ buttonName }"]`).click();
		};
		// Aktualizace jednotek po vybrani tridy
		window.addEventListener('change', function (e) {
			if (!e.target.hasAttribute('data-autosubmit-name')) { return; }

			let submitBtnName = e.target.getAttribute('data-autosubmit-name');
			customSubmit(submitBtnName);
		});
	})();

	// Autosubmit u formularovych prvku
	// Neni primo na inputu apod. kvuli prekreslovani celeho formulare
	(function () {
		var submitForm = function () {
			clearTimeout(timer);

			var form = this.parentNode;
			while (form.tagName.toLowerCase() != 'form') {
				form = form.parentNode;
				if (form.tagName.toLowerCase() == 'body') { break; }
			}
			naja.uiHandler.submitForm(form);
		};

		var timer = null;

		window.addEventListener('change', function (e) {
			if (!e.target.hasAttribute('data-autosubmit')) { return; }
			if (e.target.tagName.toLowerCase() === 'input' && e.target.type === 'text') { return; }

			clearTimeout(timer);
			timer = setTimeout(submitForm.bind(e.target), 200);
		});

		window.addEventListener('keyup', function (e) {
			if (!e.target.hasAttribute('data-autosubmit')) { return; }
			if (e.target.tagName.toLowerCase() != 'input' && e.target.tagName.toLowerCase() != 'textarea') { return; }

			if (e.key === 'Enter') {
				return;
			}

			clearTimeout(timer);
			timer = setTimeout(submitForm.bind(e.target), 200);
		});
	})();


	// Inicializace Inputmask
	function initInputmask (input) {
		var m = new Inputmask();
		m.mask(input);
	}

	setTimeout(() => { document.querySelectorAll('[data-inputmask]').forEach(initInputmask); }, 0);

	naja.afterUpdateFunctions.push(function () {
		this.querySelectorAll('[data-inputmask]').forEach(initInputmask);
	});

	// Inicializace kalendare

	// Kvuli Vue komponentam, jinak je nestihne nacist
	document.querySelectorAll('.flatpickr').forEach(initFlatpickr);

	naja.afterUpdateFunctions.push(function () {
		this.querySelectorAll('.flatpickr').forEach(initFlatpickr);
	});


	// Inicializace bubliny u info ikony
	document.querySelectorAll('div[data-info]').forEach(window.initInfoIcon);

	naja.afterUpdateFunctions.push(function () {
		this.querySelectorAll('div[data-info]').forEach(window.initInfoIcon);
	});


	// Inicializace hintboxu u inputu
	document.querySelectorAll('input[data-hintbox-url]').forEach(window.initHintbox);

	naja.afterUpdateFunctions.push(function () {
		this.querySelectorAll('input[data-hintbox-url]').forEach(window.initHintbox);
	});


	// Offline upozorneni
	var alertOffline = document.querySelector('.alert-offline');
	var mobileHeader = document.querySelector('#mobile-header');

	window.addEventListener('offline', function () {
		alertOffline.style.display = 'block';
		if (mobileHeader) { mobileHeader.style.paddingTop = '3.6rem'; }
	});
	window.addEventListener('online', function () {
		alertOffline.style.display = 'none';
		if (mobileHeader) { mobileHeader.style.paddingTop = '0'; }
	});


	// Mobilni popupy
	document.querySelectorAll('[data-mobile-popup]').forEach(window.initMobilePopup);
	document.querySelectorAll('[data-mobile-open]').forEach(window.initMobilePopup);
	document.querySelectorAll('[data-mobile-close]').forEach(window.initMobilePopup);

	naja.afterUpdateFunctions.push(function () {
		this.querySelectorAll('[data-mobile-popup]').forEach(window.initMobilePopup);
		this.querySelectorAll('[data-mobile-open]').forEach(window.initMobilePopup);
		this.querySelectorAll('[data-mobile-close]').forEach(window.initMobilePopup);

		// this.querySelectorAll('[data-mobile-popup].active .content button').forEach(window.initMobilePopup); // skryti buttonu pri submitu detailu
	});
});


// Funkcionalita fotky
window.Photo = function (div, file) {
	this.container = div;
	this.file = (file || null);

	this.enlargeBtn = div.querySelector('a.enlarge');
	this.uploadBtn = div.querySelector('a.upload');
	this.removeBtn = div.querySelector('a.remove');
	this.image = div.querySelector('img');
	this.fileInput = div.querySelector('input[type="file"]');
	this.hiddenInput = div.querySelector('input[type="hidden"]'); // 0 = ponechat fotku, 1 = odstranit fotku
	this.progress = div.querySelector('progress');

	this.url = (window.URL || window.webkitURL);
	this.imageURL = null;

	// Zvetseni fotky
	if (this.enlargeBtn) {
		this.enlargeBtn.addEventListener('click', function (e) {
			e.preventDefault();
			this.enlargePhoto();
		}.bind(this));
	}

	// Vyber fotky
	if (this.uploadBtn) {
		this.uploadBtn.addEventListener('click', function (e) {
			e.preventDefault();
			this.fileInput.click();
		}.bind(this));
	}

	// Nacteni fotky po vyberu
	if (this.fileInput) {
		this.fileInput.addEventListener('change', function () {
			if (this.fileInput.files && this.fileInput.files[0]) {
				this.file = this.fileInput.files[0];
				this.readFile();
			}
		}.bind(this));
	}

	// Nacteni fotky z predaneho souboru
	if (this.file && this.file.size) { this.readFile(); }

	// Odstraneni fotky pri stisku tlacitka
	if (this.removeBtn) {
		this.removeBtn.addEventListener('click', function (e) {
			e.preventDefault();
			this.removeFile();
		}.bind(this));
	}
};

// Metoda pro zvetseni fotky
Photo.prototype.enlargePhoto = function () {
	window.popup({
		type: 'image',
		url: (this.enlargeBtn.href || this.image.src),
	});
};

// Metoda pro nacteni fotky
Photo.prototype.readFile = function () {
	this.container.classList.add('loading');

	// Validace typu fotky
	if (this.file.type.indexOf('image') == -1) { return invalidImage.call(this); }

	// Validace nacteni obrazku
	this.url.revokeObjectURL(this.imageURL);
	this.imageURL = this.url.createObjectURL(this.file);

	var testImage = new Image();

	// Obrazek se nacetl
	testImage.onload = function () {
		this.image.src = this.imageURL;

		if (this.hiddenInput) { this.hiddenInput.value = 0; }
		if (this.enlargeBtn) { this.enlargeBtn.setAttribute('href', this.imageURL); }

		this.container.classList.remove('loading');
	}.bind(this);

	// Obrazek se nenacetl
	testImage.onerror = invalidImage.bind(this);

	// Nacist obrazek
	testImage.src = this.imageURL;

	// Odstraneni nevalidniho obrazku
	function invalidImage () {
		this.removeFile();

		new TempMessage({
			html: t('Soubor %fileName% není v podporovaném formátu.', { fileName: this.file.name }),
			type: 'warning',
		});

		this.container.classList.remove('loading');
	}
};

// Metoda pro odstraneni fotky
Photo.prototype.removeFile = function () {
	this.url.revokeObjectURL(this.imageURL);

	var src = '';
	if (this.image.hasAttribute('data-placeholder')) { src = this.image.getAttribute('data-placeholder'); }

	this.image.setAttribute('src', src);

	if (this.enlargeBtn) { this.enlargeBtn.setAttribute('href', src); }
	if (this.fileInput) {
		this.fileInput.value = '';

		if (this.fileInput.value) {
			this.fileInput.type = 'text';
			this.fileInput.type = 'file';
		}
	}

	if (this.hiddenInput) { this.hiddenInput.value = 1; }

	if (this.ajax) { this.ajax.abort(); }

	this.container.dispatchEvent(new CustomEvent('photoRemoveFile', {
		bubbles: true,
	}));
};

// Metoda pro upload fotky
Photo.prototype.uploadFile = function (url, method, dataToAppend) {
	this.container.classList.add('uploading');
	this.file.uploaded = false;

	url = url || window.location.href;
	method = method || 'POST';

	var data = new FormData();
	data.append((this.hiddenInput.getAttribute('name') || 'newPhoto'), this.file);

	// Pridat data
	if (dataToAppend) {
		for (var i = 0; i < dataToAppend.length; i++) {
			data.append(dataToAppend[i].key, dataToAppend[i].value);
		}
	}

	// Moznosti
	var options = {
		history: false,
		unique: false,
		timeout: null,
		photo: this,
	};

	// Odeslat request
	this.ajax = naja.makeRequest(method, url, data, options)
		.then((response) => {
			this.hiddenInput.setAttribute('name', response.name);
			this.container.classList.remove('uploading');
			this.file.uploaded = true;

			return response;
		})
		.catch(() => {
			this.removeFile();
			new TempMessage({
				html: t('Soubor %fileName% se nepodařilo nahrát.', { fileName: this.file.name }),
				type: 'warning',
			});
		});

	return this.ajax;
};

// Naja listener na upload fotky - aktualizace progressu
naja.addEventListener('before', function (e) {
	var photo = e.options.photo;
	if (!photo) { return; }

	e.xhr.upload.addEventListener('progress', function (progressEvent) {
		var percent = parseInt(100 * progressEvent.loaded / progressEvent.total);
		photo.progress.value = percent;
	}, false);
});


// Funkcionalita souboru
window.FileInput = function (div) {
	this.container = div;
	this.file = null;

	this.uploadBtn = div.querySelector('a.upload');
	this.removeBtn = div.querySelector('a.remove');
	this.fileBtn = div.querySelector('a.file');
	this.fileInput = div.querySelector('input[type="file"]');
	this.progress = div.querySelector('progress');

	// Nacteni souboru po vyberu
	this.fileInput.addEventListener('change', this.loadFile.bind(this));

	// Stazeni/vyber souboru
	this.fileBtn.addEventListener('click', e => {
		if (!this.fileBtn.hasAttribute('download')) {
			e.preventDefault();
			this.selectFile();
		}
	});

	// Odstraneni souboru
	this.removeBtn.addEventListener('click', e => {
		e.preventDefault();
		this.removeFile();
	});

	// Vyber souboru
	this.uploadBtn.addEventListener('click', e => {
		e.preventDefault();
		this.selectFile();
	});
};

// Metoda pro nacteni souboru po vyberu
FileInput.prototype.loadFile = function () {
	if (!this.fileInput.files || !this.fileInput.files[0]) { return; }

	this.file = this.fileInput.files[0];

	let blob = new Blob([ this.file ], { type: this.file.type });
	let fileURL = window.URL.createObjectURL(blob);

	this.fileBtn.href = fileURL;
	this.fileBtn.innerText = this.file.name;
	this.fileBtn.download = this.file.name;
};

// Metoda pro vyber souboru
FileInput.prototype.selectFile = function () {
	this.fileInput.click();
};

// Metoda pro odstraneni souboru
FileInput.prototype.removeFile = function () {
	if (this.ajax) { this.ajax.abort(); }

	this.file = null;
	this.fileInput.value = '';

	this.fileBtn.removeAttribute('download');
	this.fileBtn.href = '#';
	this.fileBtn.innerText = t('Vyberte soubor');
};

// Metoda pro upload souboru
FileInput.prototype.uploadFile = function (url, method, dataToAppend, fileKey) {
	this.container.classList.add('uploading');
	this.file.uploaded = false;

	url = url || window.location.href;
	method = method || 'POST';

	let data = new FormData();
	data.append(fileKey || 'file', this.file);

	// Pridat data
	if (dataToAppend) {
		for (let key in dataToAppend) {
			if (dataToAppend.hasOwnProperty(key)) {
				data.append(key, dataToAppend[key]);
			}
		}
	}

	// Moznosti
	var options = {
		history: false,
		unique: false,
		timeout: null,
		file: this,
	};

	// Odeslat request
	this.ajax = naja.makeRequest(method, url, data, options)
		.then((response) => {
			this.container.classList.remove('uploading');
			this.file.uploaded = true;

			return response;
		})
		.catch(err => {
			this.removeBtn.click();
			new TempMessage({
				html: t('Soubor %fileName% se nepodařilo nahrát.', { fileName: this.file.name }),
				type: 'warning',
			});
			throw err;
		});

	return this.ajax;
};

// Naja listener na upload souboru - aktualizace progressu
naja.addEventListener('before', function (e) {
	let file = e.options.file;
	if (!file) { return; }

	e.xhr.upload.addEventListener('progress', (progressEvent) => {
		let percent = parseInt(100 * progressEvent.loaded / progressEvent.total);
		file.progress.value = percent;
	}, false);
});


// Funkcionalita info ikony
window.initInfoIcon = function (div) {
	var btn = div.querySelector('a');
	var bubble = div.querySelector('div');
	var persist = false;

	function showBubble () {
		bubble.classList.add('active');
	}
	function hideBubble () {
		if (!persist) { bubble.classList.remove('active'); }
	}

	btn.addEventListener('mouseover', showBubble);
	btn.addEventListener('mouseout', hideBubble);

	btn.addEventListener('focus', showBubble);
	btn.addEventListener('blur', hideBubble);

	btn.addEventListener('click', function (e) {
		e.preventDefault();

		e.bubbleElement = bubble;
		persist = true;
		showBubble();
	});

	bubble.addEventListener('click', function (e) {
		e.bubbleElement = bubble;
	});

	window.addEventListener('click', function (e) {
		if (e.bubbleElement && e.bubbleElement == bubble) { return; }

		persist = false;
		hideBubble();
	});
};


// Funkcionalita hintboxu
window.initHintbox = function (input) {

	// Ziskat URL pro dotazy na vyplneni hintboxu
	var apiUrl = input.getAttribute('data-hintbox-url');

	// Odstranit autocomplete a autocorrect
	input.setAttribute('autocomplete', 'off');
	input.setAttribute('autocorrect', 'off');
	input.setAttribute('spellcheck', 'false');

	// Najit hintbox prirazeny k inputu
	var hintbox = document.querySelector('div[data-hintbox="' + input.id + '"]');

	if (!hintbox) {
		console.error('Chybí hintbox: div[data-hintbox="' + input.id + '"]', input);
		return;
	}

	// Najit template pro hinty
	var hintTemplate = hintbox.querySelector('[data-hint-template]');

	if (!hintTemplate) {
		console.error('Chybí template pro hint: a[data-hint-template]', hintbox);
		return;
	}

	// Odstranit template z DOM a odstranit data atributy
	hintTemplate.parentNode.removeChild(hintTemplate);
	hintTemplate.removeAttribute('data-hint-template');

	// Najit kontejner, do ktereho se vypisuji vysledky
	var results = document.querySelector('div[data-hintbox-results="' + input.id + '"]');

	if (!results) {
		console.log('Chybí kontejner, do kterého se vypisují vybrané hinty: div[data-hintbox-results="' + input.id + '"]', input);
		console.log('Je toto zamýšleno?');
	}
	else {

		// Najit template pro vybrane hinty
		var resultHintTemplate = results.querySelector('[data-hint-template]');

		if (!resultHintTemplate) {
			console.error('Chybí template pro vybrané hinty: div[data-hint-template]', results);
			return;
		}

		// Odstranit template z DOM a odstranit data atributy
		resultHintTemplate.parentNode.removeChild(resultHintTemplate);
		resultHintTemplate.removeAttribute('data-hint-template');
	}

	// Pridat tabindex na hintbox, jinak Safari ignoruje focusin
	hintbox.setAttribute('tabindex', '0');

	// Odposlouchavat change event u inputu
	input.addEventListener('input', function () {

		var getParam = input.getAttribute('data-hintbox-param');
		getParam = getParam === null ? 'value' : getParam;

		// Hledáme až od 2 znaků
		if (this.value === null || this.value.length < 1) {
			hintbox.classList.remove('show');
			return;
		}

		// Vyslat zadost o seznam polozek
		naja.makeRequest('GET', apiUrl, {
			[getParam]: this.value,
		}, {
			history: false,
		}).then(function (response) {

			// Pokud dosel seznam polozek, tak zobrazit
			if (Array.isArray(response) && response.length != 0) {
				hintbox.innerHTML = '';

				// Pro kazdy hint, ktery dosel
				response.forEach(function (hintData) {

					// Otestovat, zdali je takovy hint jiz vybrany. Pokud ano, nezobrazovat.
					if (hintsInResults.some(function (o) {
						return JSON.stringify(o.data) == JSON.stringify(hintData);
					})) { return; }

					// Naklonovat template
					var html = hintTemplate.outerHTML;

					// Nahradit promenne
					for (var prop in hintData) {
						if (hintData.hasOwnProperty(prop)) {
							html = html.replace(new RegExp('{{' + prop + '}}', 'g'), hintData[prop]);

							if (hintData[prop] === null) {
								html = html.replace(/null|\(null\)/g, '');
							}
						}
					}

					// Vytvorit hint s nahrazenym HTML
					var hint = document.createElement(null);
					hint.innerHTML = html;
					hint = hint.firstChild;

					// Vlozit do hintboxu
					hintbox.appendChild(hint);

					// Kliknuti na hint
					hint.addEventListener('click', function (e) {
						e.preventDefault();

						// Vycistit input a schovat naseptavac
						input.value = '';
						hintbox.classList.remove('show');

						// Pridat hint do vysledku
						if (results) { addHintToResults(hintData); }

						// Pokud ma odkaz class ajax, zavolat Naju
						if (this.classList.contains('ajax')) { naja.uiHandler.clickElement(this); }

						// click on certain submit
						if (input.hasAttribute('data-hintbox-submit')) { naja.uiHandler.clickElement(document.querySelector(`button[name="${ input.getAttribute('data-hintbox-submit') }"]`)); }
					});
				});

				hintbox.classList.add('show');
			}
			else { hintbox.classList.remove('show'); }
		}).
			catch(err => { console.error(err); });
	});

	// Zakazat submit pomoci Enteru
	input.addEventListener('keydown', function (e) {
		var key = e.which || e.keyCode || 0;
		if (key == 13) { e.preventDefault(); }
	});

	// Focus na input
	input.addEventListener('focus', function () {
		if (this.value != '') { input.dispatchEvent(new Event('input')); }
	});

	// Blur z inputu
	var blurTimeout = null;

	input.addEventListener('blur', function () {
		blurTimeout = setTimeout(function () {
			hintbox.classList.remove('show');
		}, 10);
	});

	// Focus do hintboxu
	hintbox.addEventListener('focusin', function () {
		clearTimeout(blurTimeout);
	});

	// Promenna, ktera drzi vybrane hinty
	var hintsInResults = [];

	// Pridani hintu do vysledku
	function addHintToResults (hintData) {

		// Naklonovat template
		var hint = resultHintTemplate.cloneNode(true);

		// Nahradit promenne
		var html = hint.innerHTML;
		for (var prop in hintData) {
			if (hintData.hasOwnProperty(prop)) {
				html = html.replace(new RegExp('{{' + prop + '}}', 'g'), hintData[prop]);

				if (hintData[prop] === null) {
					html = html.replace(/null|\(null\)/g, '');
				}
			}
		}
		hint.innerHTML = html;

		// Vlozit do kontejneru s vybranymi hinty
		results.appendChild(hint);

		// pokud ma input atribut data-hintbox-fill, ulozit a vypsat pouze aktualne vybrany hint, predchozi smazat
		if (input.hasAttribute('data-hintbox-submit') && hintsInResults.length > 0) {
			hint.parentNode.removeChild(hint.parentNode.querySelector(':first-child'));
			hintsInResults.pop();
		}

		// Pridat hint do vybranych hintu
		hintsInResults.push({
			data: hintData,
			element: hint,
		});

		// Open new tab from POS data-hintbox-result after click on hint, but not when click on delete hint
		hint.addEventListener('click', e => {
			let tagName = e.target.tagName.toLowerCase();
			if (tagName !== 'a' && tagName !== 'svg') { redirect(hint); }
		});

		// Open new tab from POS data-hintbox-result
		function redirect (hintItem) {
			let posLink = hintItem.querySelector('input').getAttribute('data-link');
			if (posLink) { window.open(posLink); }
		}

		// Listener na odstraneni hintu z vysledku
		if (hint.querySelector('[data-hint-remove]')) {
			hint.querySelector('[data-hint-remove]').addEventListener('click', function (e) {
				e.preventDefault();
				hint.parentNode.removeChild(hint);

				// Odstraneni hintu z vybranych hintu
				hintsInResults = hintsInResults.filter(function (o) {
					return o.element != hint;
				});
			});
		}
	}

	// Nacist hinty, ktere jsou jiz vybrany
	if (input.hasAttribute('data-hintbox-init') && results) {
		var initConfig = JSON.parse(input.getAttribute('data-hintbox-init'));

		if (Array.isArray(initConfig)) {
			initConfig.forEach(addHintToResults);

			if (document.querySelector('#branches-edit')) {
				initConfig.forEach(conf => {
					if (conf.productsCount > 0) {

						document.querySelectorAll('.branch-zones .zones .zone').forEach(el => {
							if (el.querySelector('span').innerHTML === conf.name) {

								el.classList.add('disabled', 'title');
								el.querySelector('a[data-hint-remove]').remove();
								el.setAttribute('title', t('Tuto zónu nelze smazat, protože jsou v ní zařazeny POSM (celkem %amount% ks).', { amount: conf.productsCount }));
							}
						});
					}
				});
			}
		}

		else { addHintToResults(initConfig); }
	}
};

// Mobilni popupy
window.initMobilePopup = function (elm) {
	var popupName;

	// Popup
	if (elm.hasAttribute('data-mobile-popup')) {
		popupName = elm.getAttribute('data-mobile-popup');
	}

	// "Open" tlacitko - zobrazit popup
	if (elm.hasAttribute('data-mobile-open')) {
		elm.addEventListener('click', function (e) {
			e.preventDefault();

			// when another popup is open, close previous + hide add button for 1s (quick fix, looks ugly without it)
			var detailPopup = document.querySelector('[data-mobile-popup="add-new-item"]');
			if (detailPopup) {
				if (detailPopup.classList.contains('active')) {

					document.querySelector('[data-mobile-open="add-new-item"]').classList.add('hidden-link');
					document.querySelector('.mobile-popup.active').close();
				}
			}

			document.querySelector('[data-mobile-popup="' + this.getAttribute('data-mobile-open') + '"]').open();
		});
		popupName = elm.getAttribute('data-mobile-open');
	}

	// "Close" tlacitko - skryt popup
	if (elm.hasAttribute('data-mobile-close')) {
		elm.addEventListener('click', function (e) {
			e.preventDefault();
			document.querySelector('[data-mobile-popup="' + this.getAttribute('data-mobile-close') + '"]').close();
		});
		popupName = elm.getAttribute('data-mobile-close');
	}

	// Najit popup a inicializovat, pokud se to jiz neprovedlo
	var popup = document.querySelector('[data-mobile-popup="' + popupName + '"]');
	console.log('popupN', popupName);
	if (popup && !popup.open) {
		var addButton = document.querySelector('.right.small .content [data-mobile-open]');
		var rightMenu = document.querySelector('[data-mobile-popup=options]');

		// Funkce pro zobrazeni popupu
		popup.open = function () {
			// Pro mobilni/tablet zobrazeni - jinak je opacity 0 a detail nelze videt
			if (rightMenu) { rightMenu.classList.add('opacity'); }

			// Hide add button
			if (addButton) { addButton.classList.add('hidden-link'); }

			popup.classList.add('active');
			document.body.classList.add('no-scroll-mobile');
		};

		// Funkce pro skryti popupu
		popup.close = function (e) {
			if (rightMenu) { rightMenu.classList.remove('opacity'); }

			// Hide add button
			if (addButton) { addButton.classList.remove('hidden-link'); }

			if (e) { e.preventDefault(); }

			popup.classList.remove('active');
			document.body.classList.remove('no-scroll-mobile');
		};

		popup.querySelector('header a.close').addEventListener('click', popup.close);
	}
};

// Mobil - Filter + Moznosti: zavirani; Kvuli BE snippet
document.addEventListener('click', function (e) {
	// Odchitit click na tlacitko close v popup
	if ((e.target.parentElement && e.target.parentElement.classList.contains('close')) ||
		e.target.classList.contains('close')) {

		// Vybrat nejblizsiho rodice(.mobile-popup) a smazat jemu class 'active'
		e.target.closest('.mobile-popup').classList.remove('active');
	}
});


// Zobrazeni hlasky u Naja requestu
window.AjaxMessage = function (naja) {

	// Funkce pro zobrazeni hlasky dle parametru z requestu
	function showAjaxMessage (obj) {
		var config = {
			type: obj.type,
		};

		if (obj.message) { config.html = obj.message; }
		// else if (obj.type === 'error') { config.html = 'Požadavek se nepodařilo provést'; }

		if (obj.button) {
			config.button = {
				title: obj.button.title,
				func: function () {
					naja.makeRequest('POST', obj.button.url, null, { history: false });
				},
			};
		}

		if (config.html || config.button) { new TempMessage(config); }
	}

	// Listener na uspesne i neuspsne requesty
	naja.addEventListener('complete', function (e) {
		if (e.response) {
			if (e.response.messages) {
				e.response.messages.forEach(obj => {
					showAjaxMessage(obj);
				});
			}
		}
	});

	// zdanlive nepotrebne - to same zastane complete naja event
	// // Listener na uspesne requesty
	// naja.addEventListener('success', function (e) {
	// showAjaxMessage(e.response, false);
	// });

	// // Listener na neuspesne requesty
	// naja.addEventListener('error', function (e) {
	// showAjaxMessage(e.response, true);
	// });

	return this;
};

naja.registerExtension(window.AjaxMessage);


// Zobrazeni/schovani mobilniho popupu po provedeni Naja requestu
window.HideMobilePopup = function (naja) {

	// Listener na dokoncene requesty
	naja.addEventListener('complete', function (e) {
		if (!e.response) { return; }
		if (e.response.mobileOpen) { document.querySelector('[data-mobile-popup="' + e.response.mobileOpen + '"]').open(); }
		if (e.response.mobileClose) { document.querySelector('[data-mobile-popup="' + e.response.mobileClose + '"]').close(); }
	});

	return this;
};

naja.registerExtension(window.HideMobilePopup);


// Listener na prekresleni HTML najou - znovu inicializovat funkcionality
naja.afterUpdateFunctions = [];
naja.snippetHandler.addEventListener('afterUpdate', function (e) {
	for (var i = 0; i < naja.afterUpdateFunctions.length; i++) {
		naja.afterUpdateFunctions[i].call(e.snippet);
	}
});

// Listener na redirect po odhlasnei po zmene role uzivatele
naja.redirectHandler.addEventListener('redirect', function (e) {
	window.location.replace(e.url);
});


// Nastaveni - nastaveni zamestnancu
document.addEventListener('DOMContentLoaded', function () {

	// Show popup pred smazanim polozky -----------------------------------------
	function showPopup () {
		if (!document.querySelector('button[data-popup-confirm]')) { return; }

		// pomoci data-popup-confirm atr. moznost nahradit text buttonu
		let popupBtnText = function (elem, type) {
			if (elem) {
				return elem.textContent;
			}
			else { return type === 'cancel' ? t('zrušit') : t('ok'); }
		};

		let deleteButtons = document.querySelectorAll('button[data-popup-confirm]');

		deleteButtons.forEach(elem => {
			elem.addEventListener('click', el => {

				window.popup({
					type: 'message',
					text: el.target.attributes['data-popup-message'].textContent,
					confirmBtn: {
						text: popupBtnText(el.target.attributes['data-popup-accept'], 'confirm'),
						callback: function () {
							document.getElementById(el.target.attributes['data-popup-clickon'].value).click(); // smaze dany element
						},
					},
					cancelBtn: {
						text: popupBtnText(el.target.attributes['data-popup-cancel'], 'cancel'),
					},
				});
			});
		});
	}

	showPopup();

	// Inicializace vsech tagu velikosti tricka
	naja.afterUpdateFunctions.push(showPopup);
});

// Scroll na data-scroll-here element (horizontalni)
document.addEventListener('DOMContentLoaded', function () {
	if (!document.querySelector('[data-scrollbar]')) { return; }

	var scrollTo = document.querySelector('[data-scroll-here]');

	let leftBoarder = document.querySelector('section.sidebar.left');
	var boarderWidth = leftBoarder ? leftBoarder.offsetWidth + leftBoarder.offsetLeft : 0;
	var overallLeftWidth = document.querySelector('main').offsetLeft + boarderWidth + 20;

	if (scrollTo) { document.querySelector('[data-scrollbar]').scrollTo(scrollTo.getBoundingClientRect().x - overallLeftWidth, 0); }
});


// Prevent multiple clicks submit
document.addEventListener('submit', e => { e.target.setAttribute('disabled', ''); });


// Grid - show/hide filters
document.addEventListener('DOMContentLoaded', function () {
	if (!document.querySelector('.grid-filter-icon')) { return; }

	var filters = document.querySelectorAll('.grid-filter-icon .funnel-show');

	function getFilterRows () {
		return document.querySelectorAll('.filter-global-row');
	}

	function setFilterActive () {
		var filterRows = getFilterRows();

		if (filterRows[0]?.classList?.contains('filter-global-row--shown')) {
			filters.forEach(item => item.classList.add('filter__funnel-small--active'));
		}
		else {
			filters.forEach(item => item.classList.remove('filter__funnel-small--active'));
		}
	}

	setFilterActive();

	filters.forEach(el => el.addEventListener('click', () => {
		var filterRows = getFilterRows();

		filterRows.forEach(item => {
			item.classList.toggle('filter-global-row--shown');
		});
		setFilterActive();
	}));

	naja.afterUpdateFunctions.push(setFilterActive);
});
