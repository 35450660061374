import { Api, Area, DeleteEventTypeIdParams, EventEvalItem, EventEvalItemRequest, EventType, EventTypeRequest, ExcuseType, ExcuseTypeRequest, GetRoleLooseParams, GetTerritoryParams, Region, RoleLight, SettingItem, SettingItemRequest, Territory } from '~/api';
import $axios from '~/plugins/axios';
import { buildModule } from '../utils';
import planning from './planning';

const api = $axios as Api;

type NewItem = {
	id: null;
	name: string;
	isOpen: boolean;
}

type ExtendedExcuseType = ExcuseType & {
	isOpen?: boolean;
};

const EVAL_ITEM_DEFAULT: Omit<EventEvalItem, 'id' | 'order'> = {
	valueType: 'text',
	name: '',
	required: false,
	options: [],
};

const EXCUSE_TYPE_DEFAULT = {
	name: '',
	hasRequest: false,
	timeAdvance: 0,
};

const EVENT_TYPE_DEFAULT = {
	name: '',
	color: null,
	start: null,
	end: null,
	roles: [],
	eventEvalItems: [],
	territories: [],
};

const REGION_ITEM_DEFAULT = {
	id: null,
	name: '',
	isOpen: true,
};

const AREA_ITEM_DEFAULT = {
	id: null,
	name: '',
	isOpen: true,
};

const TERRITORY_ITEM_DEFAULT = {
	id: null,
	name: '',
	isOpen: true,
};

const BREAKS_DEFAULT = {
	id: null,
	key: 0,
	value: 0,
};

export default buildModule()
	.withState({
		excuseTypes: [] as ExtendedExcuseType[],
		excuseTypesNew: [] as Omit<ExcuseType, 'id'>[],
		evalItems: [] as EventEvalItem[],
		evalItemsNew: [] as Omit<EventEvalItem, 'id' | 'order'>[],
		eventTypes: [] as EventType[],
		eventTypesNew: [] as Omit<EventType, 'id'>[],
		breaks: [] as SettingItem[],
		breaksNew: [] as Omit<SettingItem, 'note'>[],
		regionItemsNew: [] as NewItem[],
		areaItemsNew: [] as NewItem[],
		territoryItemsNew: [] as NewItem[],
		roles: [] as RoleLight[],
		territories: [] as Territory[],
	})
	.withGetters({
		excuseTypes: state => state.excuseTypes,
		breaks: state => state.breaks,
		roles: state => state.roles,
		territories: state => state.territories,
		eventTypes: state => state.eventTypes,
		eventTypesNew: state => state.eventTypesNew,
		evalItems: state => state.evalItems,
	})
	.withMutations({
		EXCUSE_TYPES_SUCCESS (state, excuseTypes: ExcuseType[]) {
			state.excuseTypes = excuseTypes;
		},

		ADD_EXCUSE_TYPE: (state, excuseType: ExcuseType) => {
			state.excuseTypes.push({ ...excuseType, isOpen: false });
		},

		ADD_EXCUSE_TYPE_NEW: (state) => {
			state.excuseTypesNew.push({ ...EXCUSE_TYPE_DEFAULT });
		},

		DELETE_EXCUSE_TYPE_NEW: (state, index: number) => {
			state.excuseTypesNew.splice(index, 1);
		},

		SET_EVAL_ITEMS (state, payload: EventEvalItem[]) {
			state.evalItems = payload;
		},

		ADD_EVAL_ITEM_NEW (state) {
			state.evalItemsNew.push({ ...EVAL_ITEM_DEFAULT } as Omit<EventEvalItem, 'id' | 'order'>);
		},

		DELETE_EVAL_ITEM_NEW (state, index: number) {
			state.evalItemsNew.splice(index, 1);
		},

		SET_EVENT_TYPES (state, payload: EventType[]) {
			state.eventTypes = payload;
		},

		ADD_EVENT_TYPE_NEW (state) {
			state.eventTypesNew.push({ ...EVENT_TYPE_DEFAULT });
		},

		DELETE_EVENT_TYPE_NEW (state, index: number) {
			state.eventTypesNew.splice(index, 1);
		},

		SET_EVENT_TYPE_ROLES (state, { id, roles }: {
			id: number;
			roles: RoleLight[];
		}) {
			const eventType = state.eventTypes.find(eType => eType.id === id);
			if (!eventType) {
				return;
			}
			eventType.roles = roles;
		},

		SET_EVENT_TYPE_ROLES_NEW (state, { index, roles }: {
			index: number;
			roles: RoleLight[];
		}) {
			state.eventTypesNew[index].roles = roles;
		},

		SET_EVENT_TYPE_EVAL (state, { id, items }: {
			id: number;
			items: EventEvalItem[];
		}) {
			const eventType = state.eventTypes.find(eType => eType.id === id);
			if (!eventType) {
				return;
			}
			eventType.eventEvalItems = items;
		},

		SET_EVENT_TYPE_EVAL_NEW (state, { index, items }: {
			index: number;
			items: EventEvalItem[];
		}) {
			state.eventTypesNew[index].eventEvalItems = items;
		},

		ADD_EVENT_TYPE_TERRITORY (state, { id, territory }: {
			id: number;
			territory: Territory;
		}) {
			const eventType = state.eventTypes.find(eType => eType.id === id);
			if (!eventType) {
				return;
			}
			eventType.territories.push(territory);
		},

		ADD_EVENT_TYPE_TERRITORY_NEW (state, { index, territory }: {
			index: number;
			territory: Territory;
		}) {
			state.eventTypesNew[index].territories.push(territory);
		},

		DELETE_EVENT_TYPE_TERRITORY (state, { id, territoryIndex }: {
			id: number;
			territoryIndex: number;
		}) {
			const eventType = state.eventTypes.find(eType => eType.id === id);
			if (!eventType) {
				return;
			}
			eventType.territories.splice(territoryIndex, 1);
		},
		DELETE_EVENT_TYPE_TERRITORY_NEW (state, { index, territoryIndex }: {
			index: number;
			territoryIndex: number;
		}) {
			state.eventTypesNew[index].territories.splice(territoryIndex, 1);
		},

		SET_EVENT_TYPE_VALUE (state, { id, ...update }: { id: number } & Partial<EventType>) {
			const index = state.eventTypes.findIndex(e => e.id === id);
			if (index < 0) {
				return;
			}
			state.eventTypes[index] = { ...state.eventTypes[index], ...update };
		},

		SET_EVENT_TYPE_VALUE_NEW (state, { index, ...update }: { index: number } & Partial<EventType>) {
			state.eventTypesNew[index] = { ...state.eventTypesNew[index], ...update };
		},

		// TODO remove this and refactor usages to usages of the planning module
		REGION_OPEN (_, regions: Region[]) {
			planning.state.regions = regions;
		},

		// TODO remove this and refactor usages to usages of the planning module
		AREA_OPEN (_, areas: Area[]) {
			planning.state.areas = areas;
		},

		// TODO remove this and refactor usages to usages of the planning module
		TERRITORY_OPEN (_, territories: Territory[]) {
			// @ts-expect-error TODO investigate and refactor
			planning.state.territories = territories;
		},

		ADD_REGION_ITEM_NEW (state) {
			state.regionItemsNew.push({ ...REGION_ITEM_DEFAULT });
		},

		DELETE_REGION_ITEM_NEW (state, index: number) {
			if (index) {
				state.regionItemsNew.splice(index, 1);
				return;
			}
			state.regionItemsNew = [];
		},

		ADD_AREA_ITEM_NEW (state) {
			state.areaItemsNew.push({ ...AREA_ITEM_DEFAULT });
		},

		DELETE_AREA_ITEM_NEW (state, index: number) {
			if (index) {
				state.areaItemsNew.splice(index, 1);
				return;
			}
			state.areaItemsNew = [];
		},

		ADD_TERRITORY_ITEM_NEW (state) {
			state.territoryItemsNew.push({ ...TERRITORY_ITEM_DEFAULT });
		},

		DELETE_TERRITORY_ITEM_NEW (state, index: number) {
			if (index) {
				state.territoryItemsNew.splice(index, 1);
				return;
			}
			state.territoryItemsNew = [];
		},

		SET_BREAKS (state, items: SettingItem[]) {
			state.breaks = items;
		},

		ADD_BREAK_NEW: (state) => {
			state.breaksNew.push({ ...BREAKS_DEFAULT });
		},

		DELETE_BREAK_NEW: (state, index: number) => {
			state.breaksNew.splice(index, 1);
		},

		ROLES_SUCCESS (state, roles: RoleLight[]) {
			state.roles = roles;
		},

		SET_TERRITORIES (state, newTerritories: Territory[]) {
			state.territories = newTerritories;
		},
	})
	.withActions({
		async getExcuseTypes ({ commit }) {
			const { data } = await api.get('/excuse-types');
			commit('EXCUSE_TYPES_SUCCESS', data.payload);
		},
	})
	.withActions({
		async addExcuseType ({ dispatch }, excuseType: ExcuseTypeRequest) {
			await api.post('/excuse-types', excuseType);

			dispatch('getExcuseTypes');
		},

		async updateExcuseType (
			{ dispatch },
			{ id, ...excuseType }: { id: number } & ExcuseTypeRequest,
		) {
			await api.put(`/excuse-types/${ id }`, excuseType);

			dispatch('getExcuseTypes');
		},

		async deleteExcuseType ({ dispatch }, id: number) {
			await api.delete(`/excuse-types/${ id }`);

			dispatch('getExcuseTypes');
		},

		async getEvalItems ({ commit }) {
			const { data } = await api.get('/event-eval-item');
			commit('SET_EVAL_ITEMS', data.payload);
		},
	})
	.withActions({
		async addEvalItem ({ dispatch }, item: EventEvalItemRequest) {
			await api.post('/event-eval-item', item);

			dispatch('getEvalItems');
		},

		async sortEvalItem ({ dispatch }, items: number[]) {
			await api.post('/event-eval-item/sort', { order: items });

			dispatch('getEvalItems');
		},

		async updateEvalItem (
			{ dispatch },
			{ id, ...item }: { id: number } & EventEvalItemRequest,
		) {
			await api.put(`/event-eval-item/${ id }`, item);

			dispatch('getEvalItems');
		},

		async deleteEvalItem ({ dispatch }, id: number) {
			await api.delete(`/event-eval-item/${ id }`);

			dispatch('getEvalItems');
		},

		async getEventTypes ({ commit }) {
			const { data } = await api.get('/event-type');
			commit('SET_EVENT_TYPES', data.payload);
		},
	})
	.withActions({
		async addEventType ({ dispatch }, item: EventTypeRequest) {
			await api.post('/event-type', item);

			dispatch('getEventTypes');
		},

		async updateEventType ({ dispatch }, { id, item }: {
			id: number;
			item: EventTypeRequest;
		}) {
			await api.put(`/event-type/${ id }`, item);

			dispatch('getEventTypes');
		},

		async deleteEventType ({ dispatch }, removalParams: DeleteEventTypeIdParams & { id: number }) {
			const { id, ...params } = removalParams;

			await api.delete(`/event-type/${ id }`, { params });

			dispatch('getEventTypes');
		},

		async addRegion ({ dispatch }, regionName: string) {
			await api.post('/region', { name: regionName });

			dispatch('planner/planning/getRegion', undefined, { root: true });
		},

		async editRegion ({ dispatch }, { regionName, regionId }: {
			regionName: string;
			regionId: number;
		}) {
			await api.put(`/region/${ regionId }`, { name: regionName });

			dispatch('planner/planning/getRegion', undefined, { root: true });
		},

		async deleteRegion ({ dispatch }, regionId: number) {
			await api.delete(`/region/${ regionId }`);

			dispatch('planner/planning/getRegion', undefined, { root: true });
		},

		async addArea ({ dispatch }, { areaName, regionId }: {
			areaName: string;
			regionId: number;
		}) {
			await api.post('/area', { name: areaName, regionId: regionId });

			dispatch('planner/planning/getArea', { regionId: regionId }, { root: true });
		},

		async editArea ({ dispatch }, { areaName, areaId, regionId }: {
			areaName: string;
			areaId: number;
			regionId: number;
		}) {
			await api.put(`/area/${ areaId }`, { name: areaName, regionId: regionId });

			dispatch('planner/planning/getArea', { regionId: regionId }, { root: true });
		},

		async deleteArea ({ dispatch }, { areaId, regionId }: {
			areaId: number;
			regionId: number;
		}) {
			await api.delete(`/area/${ areaId }`);

			dispatch('planner/planning/getArea', { regionId: regionId }, { root: true });
		},

		async addTerritory ({ dispatch }, { territoryName, areaId }: {
			territoryName: string;
			areaId: number;
		}) {
			await api.post('/territory', { name: territoryName, areaId: areaId });

			dispatch('planner/planning/getTerritory', { areaId: areaId }, { root: true });
		},

		async editTerritory ({ dispatch }, { territoryName, territoryId, areaId }: {
			territoryName: string;
			territoryId: number;
			areaId: number;
		}) {
			await api.put(`/territory/${ territoryId }`, { name: territoryName, areaId: areaId });

			dispatch('planner/planning/getTerritory', { areaId: areaId }, { root: true });
		},

		async deleteTerritory ({ dispatch }, { territoryId, areaId }: {
			territoryId: number;
			areaId: number;
		}) {
			await api.delete(`/territory/${ territoryId }`);

			dispatch('planner/planning/getTerritory', { areaId: areaId }, { root: true });
		},

		async getBreaks ({ commit }) {
			const { data } = await api.get('/setting/planner-breaks');
			commit('SET_BREAKS', data.payload.items);
		},
	})
	.withActions({
		async addBreak ({ dispatch }, item: SettingItemRequest) {
			await api.post('/setting/planner-breaks', item);

			dispatch('getBreaks');
		},

		async updateBreak ({ dispatch }, item: SettingItemRequest & { id: number }) {
			await api.put(`/setting/planner-breaks/${ item.id }`, item);

			dispatch('getBreaks');
		},

		async deleteBreak ({ dispatch }, id: number) {
			await api.delete(`/setting/planner-breaks/${ id }`);

			dispatch('getBreaks');
		},

		async getRoles ({ commit }, customParams: Omit<GetRoleLooseParams, 'isInPlanner'> = {}) {
			const params = { ...customParams, isInPlanner: true };
			const { data } = await api.get('/role/loose', { params });
			commit('ROLES_SUCCESS', data.payload);
		},

		async getTerritories ({ commit }, params: GetTerritoryParams = {}) {
			const territoriesResp = await api.get('/territory', { params });

			commit('SET_TERRITORIES', territoriesResp.data.payload);
		},
	});
